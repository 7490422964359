@import "~@splidejs/splide/dist/css/splide.min.css";
.diferentials-splide li, .products-lines .line__content-name, .certifications__image {
  transition: all 0.5s ease-in-out;
}

.diferentials-splide li {
  box-shadow: 0px 4px 28px -10px rgba(0, 0, 0, 0.15);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 359px;
  min-height: 292px;
  box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.18);
  padding: 16px;
  margin: auto;
  border-radius: 11px;
}
.card__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 91px;
  min-height: 91px;
  text-align: center;
  padding: 8px;
  margin-bottom: 1.5rem;
  border-radius: 50%;
}
.card__icon i {
  font-size: 2.813rem;
}
.card__icon--image {
  min-width: 146px;
  min-height: 146px;
}
.card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card__title {
  font-weight: 900;
  margin-bottom: 1rem;
  text-align: center;
}
.card__subtitle {
  text-align: center;
}
.card__text {
  text-align: center;
}
.splide__arrow {
  background: transparent;
}
.splide__arrow--next {
  right: -1em;
}
@media (max-width: 599px) {
  .splide__arrow--next {
    right: 0em;
  }
}
.splide__arrow--prev {
  left: -1em;
}
@media (max-width: 599px) {
  .splide__arrow--prev {
    left: 0em;
  }
}
.splide__arrow svg {
  fill: #a7a7a7;
}
@media (min-width: 1200px) {
  .splide__arrow--next {
    right: -2em;
  }
  .splide__arrow--prev {
    left: -2em;
  }
}
.splide__pagination {
  bottom: -1.5em;
}
.splide__pagination__page {
  width: 18px;
  height: 6px;
  border-radius: 100px;
}
.splide__pagination__page.is-active {
  background: #1D2D50;
}

.banner {
  position: relative;
  overflow: hidden;
}
.banner-splide li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-splide li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 599px) {
  .banner-splide li img {
    width: 425px;
  }
}
.banner-splide__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 100%;
  height: auto;
  padding: 1rem 0.5rem;
  transform: translate(-50%, -50%);
}
@media (min-width: 1200px) {
  .banner-splide__content {
    max-width: fit-content;
    height: auto;
    background-color: transparent;
    left: 14em;
    transform: translateY(-50%);
  }
}
.banner-splide__content h2 {
  color: white;
  font-size: 3.25rem;
  font-weight: 900;
}
@media (max-width: 990px) {
  .banner-splide__content h2 {
    font-size: 1.5rem;
  }
}
.banner-splide__content h2 strong {
  font-weight: 900;
  color: #d3e741;
}
.banner-splide__content p {
  color: white;
}
@media (max-width: 599px) {
  .banner-splide__content p {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .banner-splide__content p {
    font-size: 1.125rem;
  }
}
.banner-splide__content p:last-of-type {
  margin-bottom: 1rem;
}
.banner-splide__button {
  min-width: 134px;
  height: 42px;
}
@media (max-width: 599px) {
  .banner-splide__button {
    max-width: 92px;
    font-size: 0.875rem;
  }
}
.banner .splide__arrow--next {
  right: 2rem;
}
.banner .splide__arrow--prev {
  left: 2rem;
}
.banner .splide__arrow svg {
  width: 29px;
  height: 46px;
  fill: #FFFFFF;
}
.banner .splide__pagination {
  bottom: 1.5rem;
}
.banner .splide__pagination__page {
  border: 1px solid #FFFFFF;
}
.banner .splide__pagination__page:not(.is-active) {
  background-color: #FFFFFF;
  border: 1px solid #1D2D50;
}
@media (min-width: 600px) and (max-width: 899px) {
  .banner .splide__arrow {
    top: 60%;
  }
}

.diferentials {
  margin-top: -4rem;
}
.diferentials-splide li {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 0.5rem;
}
.diferentials-splide li:hover {
  background: #1D2D50;
  cursor: pointer;
}
.diferentials-splide li:hover .diferentials-splide__title,
.diferentials-splide li:hover .diferentials-splide__content > p {
  color: #FFFFFF;
}
.diferentials-splide li:hover .diferentials-splide__image {
  background: #FFFFFF;
}
.diferentials-splide li:hover .diferentials-splide__image img {
  filter: invert(1);
}
.diferentials-splide__image {
  background: #1D2D50;
  border-radius: 50%;
  padding: 0.75rem;
  width: 60px;
  height: 60px;
  text-align: center;
}
.diferentials-splide__title {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #090E0D;
  font-weight: bold;
}
.diferentials-splide__content > p {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #464646;
}
.diferentials .splide__list {
  padding: 1rem !important;
}

.products-lines__title {
  font-family: "Roboto", sans-serif;
  margin-bottom: 4rem;
  font-size: 2.25rem;
}
.products-lines__card {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 48px 42px auto;
  gap: 1rem;
  margin-bottom: 4rem;
  border: 1px solid #1D2D50;
  border-radius: 20px;
  text-align: center;
  justify-items: center;
}
.products-lines__card:hover .line__content-button {
  background: #172440;
}
.products-lines .line__content-image {
  width: 173px;
  height: 173px;
  object-fit: cover;
}
@media (max-width: 599px) {
  .products-lines .line__content-image {
    width: 100px;
    height: 100px;
  }
}
.products-lines .line__content-name {
  font-size: 1.5rem;
  font-weight: bold;
}
@media (max-width: 599px) {
  .products-lines .line__content-name {
    font-size: 1.125rem;
  }
}
.products-lines .line__content-summary p {
  color: #464646;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
}
.products-lines .line__content-button {
  background: #1D2D50;
  color: #FFFFFF;
  min-width: 130px;
  border-radius: 4px;
}
@media (max-width: 599px) {
  .products-lines .line__content-button {
    font-size: 1.125rem;
  }
}
.products-lines .splide__arrow svg {
  width: 29px;
  height: 46px;
  fill: #1D2D50;
}

.solutions {
  background: rgba(217, 217, 217, 0.3);
}
.solutions__title {
  font-family: "Roboto", sans-serif;
  margin-bottom: 4rem;
  font-size: 2.25rem;
}
.solutions__card {
  border-radius: 20px;
  text-align: center;
  padding: 1rem;
}
.solutions__card:nth-child(odd) {
  background: #1D2D50;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.solutions__card:nth-child(odd) .solutions__card__title,
.solutions__card:nth-child(odd) .solutions__card__summary p {
  color: #FFFFFF;
}
.solutions__card:nth-child(odd) .solutions__card__button {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.solutions__card:nth-child(even) {
  background: rgba(217, 217, 217, 0.29);
}
.solutions__card:nth-child(even) img {
  filter: invert(1);
}
.solutions__card:nth-child(even) .solutions__card__title {
  color: #1D2D50;
}
.solutions__card:nth-child(even) .solutions__card__summary p {
  color: #464646;
}
.solutions__card:nth-child(even) .solutions__card__button {
  border: 1px solid #1D2D50;
  color: #1D2D50;
}
.solutions__card__title {
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
}
.solutions__card__summary {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.solutions__card__button {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  background: transparent;
  border-radius: 4px;
  padding: 3px 3rem;
}

.news__title {
  font-family: "Roboto", sans-serif;
  color: #1D2D50;
  font-size: 1.875rem;
}
.news__info {
  border: 1px solid #1D2D50;
  border-radius: 30px;
  text-align: center;
}
.news__info > *,
.news__info > * b {
  font-family: "Roboto", sans-serif;
  color: #1D2D50;
}
.news__info h3 {
  font-size: 3.25rem;
}
.news__info p,
.news__info b {
  font-size: 1.5rem;
}
.news-card {
  background-color: rgba(109, 109, 109, 0.08);
  border-radius: 40px;
  box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.news-card__figure img {
  border-radius: 30px;
  min-height: 188px;
}
@media (max-width: 599px) {
  .news-card__figure img {
    max-height: 211px;
  }
}
.news-card__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.news-card__info p {
  font-family: "Plus Jakarta Sans", sans-serif;
}
.news-card__info p:last-child {
  color: #1D2D50;
  font-weight: 500;
}
.news-card__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  color: #1D2D50;
}
.news-card__text > p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.875rem;
}
.news-card__link {
  text-decoration: underline;
  text-decoration-color: #1D2D50;
  color: #1D2D50;
}
.news-card__link:hover {
  color: #4a5773;
}

.about {
  background: rgba(217, 217, 217, 0.3);
}
.about-title {
  color: #464646;
}
.about-title::after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  border-radius: 4px;
  background-color: #DCE689;
}
.about-description {
  color: #090E0D;
}
.about-description h3 {
  font-size: 1.5rem;
  color: #161815;
}

.certifications__title {
  font-family: "Roboto", sans-serif;
  margin-bottom: 4rem;
  font-size: 2.25rem;
}
.certifications__image {
  max-width: 174px;
  max-height: 134px;
  filter: saturate(0);
}
.certifications__image:hover {
  filter: saturate(1);
  cursor: pointer;
}
@media (max-width: 599px) {
  .certifications__image {
    max-width: 120px;
  }
}